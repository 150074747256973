import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw, RouteLocationRaw } from 'vue-router'
import { redirectRouter } from '@/hooks/router-redirect'


const projectChildrenRoutes: CustomRouteRecordRaw[] = [
  // 项目管理
  {
    path: 'project-list',
    name: 'projectList',
    component: () => import('@/views/project/project-list.vue'),
    meta: {
      title: '项目列表',
      iconfont: 'icon-a-text_snippet_black_24dp1',
      requiredPermission: 'PROJECT_LIST',

    },

  },
  {
    path: 'project-detail',
    name: 'projectDetail',
    component: () => import('@/views/project/project-detail.vue'),
    meta: {
      title: '项目详情',
      hideInMenu: true,
      parentNameForBreadcrumb: 'projectList',
      requiredPermission: 'PROJECT_DETAIL'
    },
  },

  {
    path: 'customer-list',
    name: 'customerList',
    component: () => import('@/views/project/customer/customer-list.vue'),
    meta: {
      title: '用工单位列表',
      iconfont: 'icon-perm_identity_black_24dp',
      requiredPermission: 'CUSTOMER_LIST',
    },
  },
  {
    path: 'contract-company-list',
    name: 'contractCompanyList',
    component: () => import('@/views/project/customer/contract-company-list.vue'),
    meta: {
      title: '签约公司管理',
      iconfont: 'icon-article-line1',
      requiredPermission: 'CONTRACT_COMPANY_LIST',
    },
  },
  {
    path: 'social-report',
    name: 'socialReport',
    component: () => import('@/views/project/social/social-report.vue'),
    meta: {
      title: '社保报表',
      iconfont: 'icon-scroll',
      requiredPermission: 'SOCIAL_REPORT',
    }
  },
  {
    path: 'social-report-detail/:id',
    name: 'socialReportDetail',
    component: () => import('@/views/project/social/social-report-detail.vue'),
    meta: {
      title: '社保报表明细',
      hideInMenu: true,
      parentNameForBreadcrumb: 'socialReport'
    }
  },
  // 工资单
  {
    path: 'payroll-list',
    name: 'payrollList',
    component: () => import('@/views/project/payroll/payroll-list.vue'),
    meta: {
      title: '工资单发送记录',
      iconfont: 'icon-bank-card-line',
      requiredPermission: 'PAYROLL_LIST',
    }
  },
  {
    path: 'payroll-feedback-list',
    name: 'payrollFeedbackList',
    component: () => import('@/views/project/payroll/feedback-list.vue'),
    meta: {
      title: '工资单反馈',
      iconfont: 'icon-message-2-line',
      showBadge: 'wageFeedbackCount',
      requiredPermission: 'PAYROLL_FEEDBACK_LIST',
    }
  },
  {
    path: 'payroll-detail/:id',
    name: 'payrollDetail',
    component: () => import('@/views/project/payroll/payroll-detail.vue'),
    meta: {
      title: '工资单详情',
      hideInMenu: true,
      parentNameForBreadcrumb: 'payrollList'
    }
  },
  {
    path: 'payroll-import',
    name: 'payrollImport',
    component: () => import('@/views/project/payroll/payroll-import.vue'),
    meta: {
      title: '工资单创建',
      hideInMenu: true,
      parentNameForBreadcrumb: 'payrollList'
    }
  },

  {
    path: 'hourly-wage-list',
    name: 'hourlyWageList',
    component: () => import('@/views/project/hourly-wage/hourly-wage-list.vue'),
    meta: {
      title: '时薪设置',
      iconfont: 'icon-vip-diamond-line',
      requiredPermission: 'HOURLY-WAGE',
    },
  },
  {
    path: 'client-project-list',
    name: 'clientProjectList',
    component: () => import('@/views/project/client/client-project-list.vue'),
    meta: {
      title: '门店人才库',
      iconfont: 'icon-account-pin-box-line1',
      requiredPermission: 'CLIENT_PROJECT_LIST',
    },
  },
  {
    path: 'hiring-list',
    name: 'hiringList',
    component: () => import('@/views/project/hiring/hiring-list.vue'),
    meta: {
      title: '招聘需求',
      iconfont: 'icon-user-shared-line',
      requiredPermission: 'HIRING_LIST',
    },
  },
  {
    path: 'hiring-detail/:id',
    name: 'hiringDetail',
    component: () => import('@/views/project/hiring/hiring-detail.vue'),
    meta: {
      title: '招聘需求详情',
      hideInMenu: true,
      actionRouterName: 'hiringList',
      parentNameForBreadcrumb: 'hiringList',
      iconfont: 'icon-user-shared-line',
      requiredPermission: 'HIRING_DETAIL',
    },
  },
]

export const projectRoutes: CustomRouteRecordRaw = {
  path: 'project',
  name: 'project',
  component: EmptyLayout,
  redirect: (): RouteLocationRaw => redirectRouter(projectChildrenRoutes),
  meta: {
    title: '项目管理',
    iconfont: 'icon-clipboard-text',
    desc: '以项目为维度内容',
    requiredPermission: 'PROJECT',
    noQuick: true
  },
  children: projectChildrenRoutes,
}
