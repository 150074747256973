<template>
  <custom-api-select
    :api="projectApi.getProjectList"
    :api-params="apiParams"
    :immediate="true"
    value-field="id"
    label-field="name"
  />
</template>
<script lang="ts" setup>
import { projectApi } from '@/api/project'
import { projectApiSelectProps } from './props'
const props = defineProps(projectApiSelectProps)
const { apiParams } = props
</script>
