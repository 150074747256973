import { redirectRouter } from '@/hooks/router-redirect'
import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw, RouteLocationRaw } from 'vue-router'
const systemChildrenRoutes = [
  // 企业管理员列表
  {
    path: 'admin-list',
    name: 'adminList',
    component: () => import('@/views/system/admin-list.vue'),
    meta: {
      title: '用户列表',
      iconfont: 'icon-people_outline_black_24dp',
      requiredPermission: 'ADMIN_LIST',
    },
  },
  {
    path: 'role-list',
    name: 'roleList',
    component: () => import('@/views/system/role-list.vue'),
    meta: {
      title: '角色管理',
      iconfont: 'icon-sentiment_very_satisfied_black_24dp',
      requiredPermission: 'ROLE_LIST',
    },
  },
  {
    path: 'social-list',
    name: 'socialList',
    component: () => import('@/views/system/social-list.vue'),
    meta: {
      title: '社保公积金政策',
      iconfont: 'icon-shield_black_24dp',
      requiredPermission: 'SOCIAL_LIST',
    },
  },
  {
    path: 'notification-list',
    name: 'notificationList',
    component: () => import('@/views/system/notification-list.vue'),
    meta: {
      title: '消息列表',
      iconfont: 'icon-notifications_black_24dp',
      requiredPermission: 'NOTIFICATION_LIST',
      showBadge: 'unreadMessageCount'
    },
  },
  {
    path: '/notification-setting',
    name: 'notificationSetting',
    component: () => import('@/views/system/notification-setting.vue'),
    meta: {
      title: '消息设置',
      iconfont: 'icon-chat-settings-line',
      requiredPermission: 'NOTIFICATION_SETTING',
    },
  },
  {
    path: 'mini-program-manage',
    name: 'MiniProgramManage',
    component: () => import('@/views/system/mini-program-manage.vue'),
    meta: {
      title: '小程序登记',
      hideInMenu: true,
      iconfont: 'icon-todo-fill',
      requiredPermission: 'MINI_PROGRAM_MANAGE',
    },
  },
  {
    path: 'custom-field-list',
    name: 'customFieldList',
    component: () => import('@/views/system/custom-field-list.vue'),
    meta: {
      title: '自定义字段设置',
      iconfont: 'icon-settings-fill',
      requiredPermission: 'CUSTOM_FIELD_LIST',
    }
  },
  // 岗位管理
  {
    path: 'job-list',
    name: 'jobList',
    component: () => import('@/views/system/job-list.vue'),
    meta: {
      title: '岗位管理',
      iconfont: 'icon-briefcase-line',
      requiredPermission: 'JOB_LIST',
    },
  },
]
export const systemRoutes: CustomRouteRecordRaw = {
  path: 'system',
  name: 'system',
  component: EmptyLayout,
  redirect: (): RouteLocationRaw => redirectRouter(systemChildrenRoutes),
  meta: {
    title: '系统设置',
    iconfont: 'icon-icon_chilun',
    desc: '以系统为维度内容',
    requiredPermission: 'SYSTEM',
    noQuick: true
  },
  children: systemChildrenRoutes
}
