import { request } from '@/utils/request'

// 客户管理
const customerManage = {
  //  【】获取客户列表
  getCustomerList: params => request.get('project_company/project_companys/index', params),

  /* 【】 创建客户 */
  addCustomer: params => request.post('/ProjectCompany/ProjectCompanys/store', params),

  /* 编辑客户 */
  updateCustomer: (id, params) => request.post(`/ProjectCompany/ProjectCompanys/update/${id}`, params),

  /* 添加签约公司 */
  addContractSubject: params => request.post('ProjectCompany/ProjectCompanys/contractSubjectStore', params),

  /* 编辑签约公司 */
  editContractSubject: (id, params) => request.post(`ProjectCompany/ProjectCompanys/contractSubjectStore/${id}`, params),

  /* 禁用、启动签约公司 */
  banContractSubject: (id, params) => request.post(`ProjectCompany/ProjectCompanys/changeContractSubjectStatus/${id}`, params),

}
export { customerManage }