import { ExtractPropTypes, PropType } from 'vue'
import { Required } from 'utility-types'


type SelectorTypes = 'contract_type' | 'bank' | 'project_type' | 'social' // todo 根据字典列表动态生成类型


export const commonApiSelectProps = {
  commonApiSelectType: {
    type: String as PropType<SelectorTypes>,
    required: true
  },
  needChineseValue: {
    type: Boolean,
    default: false
  }
}

export type CommonApiSelectProps = Required<Partial<ExtractPropTypes<typeof commonApiSelectProps>>, 'commonApiSelectType'>

