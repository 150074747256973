import { request } from '@/utils/request'

// 岗位管理接口
const jobApi = {

  getJobList: params => request.get('projects/project_company_jobname/lists', params),

  addJob: params => request.post('projects/project_company_jobname/store', params),

  deleteJob: (id, params) => request.post(`projects/project_company_jobname/destroy/${id}`, params),

  updateJob: (id, params) => request.post(`projects/project_company_jobname/update/${id}`, params),

}

export { jobApi }


