<template>
  <icon-font :type="name" />
</template>
<script lang="ts" setup>
import { createFromIconfontCN } from '@ant-design/icons-vue'

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_3433631_jkhvujvj2z.js',
})

defineProps({
  name: {
    type: String,
    required: true,
  },
})

</script>
