import { PropType, ExtractPropTypes, Component } from 'vue'
import { DrawerProps } from 'ant-design-vue/es'
type TAction = {
  label: string,
  isShow?: boolean,
  // 按钮类型
  type?: 'link' | 'default' | 'primary' | 'ghost' | 'dashed' | 'text',
  confirm: string | undefined,
  click: () => void
}
type BotBtn = {
  label: string,
  isShow?: boolean,
  // 按钮类型
  type?: 'link' | 'default' | 'primary' | 'ghost' | 'dashed' | 'text',
  click: () => void
}
export const basicDrawerProps = {
  title: String as PropType<string>,
  statusTip: Object as PropType<{
    text: string,
    textClass?: string,
    bgColor?: string,
  }>,
  // slug上面的位置的文本
  slugTip: Object as PropType<{
    text: string,
    textClass?: string,
  }>,
  slug: String as PropType<string>,
  visible: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  width: {
    type: Number as PropType<number | string>,
    default: 600,
  },

  loading: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  reloadable: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  showFooter: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  showConfirm: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  onConfirm: {
    type: Function as PropType<Fn>,
    default: () => { }
  },
  // 显示取消按钮
  showCancel: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  // 取消自动隐藏窗口
  autoCancelVisible: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  onCancel: {
    type: Function as PropType<Fn>,
    default: () => { }
  },
  showClose: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  confirmText: {
    type: String as PropType<string>,
    default: '确定',
  },
  cancelText: {
    type: String as PropType<string>,
    default: '取消',
  },
  bottomActions: {
    type: Array<TAction>,
    default: []
  },
  bottomBtns: {
    type: Array<BotBtn>,
    default: []
  }
}

export const descDrawerProps = {
  ...basicDrawerProps,
  schemas: {
    type: Array as PropType<IGridTable.ColumnProps[]>,
  },
  model: {
    type: Object as PropType<Recordable>,
  },
  flowable: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  tabs: Array as PropType<Component[]>,
}

export type BasicDrawerProps = Partial<ExtractPropTypes<typeof basicDrawerProps>> & DrawerProps
