<style lang="less" scoped>
@nav-size-height: 60px;
@layout-max-width: 1100px;

.layout {
  height: 100vh;
  width: 100%;
}

.layout-navbar {
  padding: 0;
  // background-color: #e94d07;
  background-color: #21209C;
}

.layout-sider {
  position: "fixed";
  top: 0;
  bottom: 0;
  left: 0;
  overflow: "auto";
  height: "100vh";

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 10px 20px;
    height: 55px;
    background: #FFFFFF;
    box-sizing: border-box;

    .logo-text,
    .logo-collapsed {
      height: 37px;
      width: auto;
    }

    &.collapsed {
      margin: 12px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("@images/logo.png");
    }
  }
}
</style>

<template>
  <a-layout
    class="layout"
    style="height: 100vh;"
    theme="light"
  >
    <a-layout-header class="flex shadow layout-navbar">
      <div
        style="width: 170px;"
        class="flex items-center justify-center"
      >
        <img
          style="height: 37px;"
          src="@images/logo.png"
        >
      </div>
      <nav-bar @handleSideVisible="handleSideVisible" />
    </a-layout-header>

    <a-layout>
      <a-layout-sider
        v-if="sideVisible"
        v-model:collapsed="collapsed"
        class="layout-sider"
        collapsible
        :width="245"
        :collapsed-width="48"
        theme="dark"
      >
        <Menu />
      </a-layout-sider>
      <a-layout-content class="overflow-auto">
        <Content />
      </a-layout-content>
      <!-- <a-layout-footer>
        <Footer />
      </a-layout-footer> -->
    </a-layout>
  </a-layout>
</template>

<script lang="ts" setup>
import NavBar from './components/navbar/index.vue'
import Menu from './components/menu.vue'
import Content from './components/content.vue'

const collapsed = ref(false)

const sideVisible = ref(true)
function handleSideVisible(v:boolean) {
  sideVisible.value = v
}
</script>
