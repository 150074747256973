import { redirectRouter } from '@/hooks/router-redirect'
import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw, RouteLocationRaw } from 'vue-router'
const examineChildrenRoutes = [
  {
    path: '/oa-list',
    name: 'oaList',
    component: () => import('@/views/attendance/oa/oa-list.vue'),
    meta: {
      title: '审核列表',
      iconfont: 'icon-server-line',
      requiredPermission: 'OA_APPROVAL',
      showBadge: 'pendingApprovalCount',
    }
  },
  {
    path: '/oa-setting',
    name: 'oaSetting',
    component: () => import('@/views/attendance/oa/oa-setting.vue'),
    meta: {
      title: '审核流设置',
      iconfont: 'icon-file-edit-line',
      requiredPermission: 'OA_SETTING_LIST',
    },
  },
]
export const examineRoutes: CustomRouteRecordRaw = {
  path: 'examine',
  name: 'examine',
  component: EmptyLayout,
  redirect: (): RouteLocationRaw => redirectRouter(examineChildrenRoutes),
  meta: {
    title: '单据审批',
    iconfont: 'icon-emotion-laugh-line',
    desc: '以审批为维度内容',
    requiredPermission: 'ATTENDANCE',
    needIcon: true,
    noQuick: true,
    notification: 'pendingApprovalCount'
  },
  children: examineChildrenRoutes
}

