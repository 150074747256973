import { ExtractPropTypes } from 'vue'
import type { SelectProps } from 'ant-design-vue/es/select'

export const projectApiSelectProps = {
  apiParams: {
    type: Object,
    default: () => ({}),
  }
}

export type ProjectApiSelectProps = Partial<ExtractPropTypes<typeof projectApiSelectProps>> & SelectProps