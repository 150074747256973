import { defineStore } from 'pinia'
import { globalApi } from '@/api/global'
import { messageApi } from '@/api/system'
import { isNumber, isString } from 'lodash-es'
import { localReference } from '@/types/local-reference'
import { Stoppable, useTimeoutFn } from '@vueuse/core'
import { projectApi } from '@/api/project'
import { router } from '@/router'
import {
  setItem,
  getItem,
} from '@/utils/storage'
import { Model } from '@/types/model'
const referenceColorMap = {
  green: '#00B42A',
  orange: '#FF9A2E',
  red: '#F53F3F',
}
interface AreaDataItem {
  label: string;
  value: string;
  children: AreaDataItem[]
}
interface GlobalState {

  /** 枚举类型 */
  reference: Nullable<
    Partial<{ [key in Reference.ReferenceKey]: Reference.ReferenceItem[] }>
  >;
  referenceColorMap: typeof referenceColorMap;

  notifications: Model.Notification;
  messages: Model.MessageItem[];
  areaData: AreaDataItem[];
}

let timer: Nullable<Stoppable>

/** app 全局信息 */
export const useAppStore = defineStore('App', {
  state: (): GlobalState => ({
    reference: null,

    /** reference 中配置的颜色转换器 */
    referenceColorMap,

    notifications: {} as Model.Notification,

    // 顶部小铃铛的消息列表
    messages: [],

    // 省市区数据
    areaData: getItem('areaData') || []
  }),

  actions: {

    /** 获取枚举值详情 */
    getReferenceLabel(
      referenceKey: Reference.ReferenceKey,
      value: number
    ): string {
      const referenceItem = this.getReferenceItems(referenceKey)
      if (!isNumber(value) && !isString(value)) {
        return ''
      }
      return (
        referenceItem.find(item => item.value.toString() === value.toString())
          ?.label || ''
      )
    },

    /** 获取枚举值列表 */
    getReferenceItems(referenceKey: Reference.ReferenceKey): Reference.ReferenceItem[] {
      return this.reference?.[referenceKey] || []
    },

    /** 获取枚举值的值映射列表 */
    getReferenceItemsValueMap(referenceKey: Reference.ReferenceKey): Record<number, Reference.ReferenceItem> {
      let valueMap: Record<number, Reference.ReferenceItem> = {}
      this.getReferenceItems(referenceKey).forEach(item => {
        valueMap[item.value] = toRaw(item)
      })
      return valueMap
    },

    /** 获取枚举值的值映射列表 */
    getReferenceItemsLabelMap(referenceKey: Reference.ReferenceKey): Record<string, Reference.ReferenceItem> {
      let labelMap: Record<string, Reference.ReferenceItem> = {}
      this.getReferenceItems(referenceKey).forEach(item => {
        labelMap[item.label] = toRaw(item)
      })
      return labelMap
    },

    /** 获取枚举值常量 map */
    getReferenceConstantMap<T extends Reference.ReferenceKey>(referenceKey: T): Reference.ConstantKeyMap[T] {
      let constantMap = {}
      this.getReferenceItems(referenceKey).forEach(item => {
        constantMap[item.key!] = toRaw(item)
      })
      return constantMap as Reference.ConstantKeyMap[T]
    },

    /** 重置枚举值 */
    async refreshReference() {
      this.reference = await globalApi.getReference()
      this.reference = Object.assign(localReference, this.reference)

      /** 设置枚举颜色值 */

      const paymentAccountStatusEnum = this.getReferenceConstantMap('paymentAccountStatusEnum')
      paymentAccountStatusEnum.CREATING.bgColor = '#FF7D00FF'
      paymentAccountStatusEnum.FAIL.bgColor = 'red'
      paymentAccountStatusEnum.FROZEN.bgColor = '#D9D9D9FF'
      paymentAccountStatusEnum.SUCCESS.bgColor = '#26BF26FF'
      paymentAccountStatusEnum.WAIT_CREATE.bgColor = '#8a89cbFF'


      const staffUserSalaryPayStatusEnum = this.getReferenceConstantMap('staffUserSalaryPayStatusEnum')
      staffUserSalaryPayStatusEnum.FAIL_CHECK_APPROVED.bgColor = '#f7bfbf'
      staffUserSalaryPayStatusEnum.FAIL_CHECK_APPROVED.color = '#ff0000'
      staffUserSalaryPayStatusEnum.AUDITING.color = '#FF7D00FF'
      staffUserSalaryPayStatusEnum.AUDITING.bgColor = '#FF7D001A'
      staffUserSalaryPayStatusEnum.CANCELED.color = '#ff0000'
      staffUserSalaryPayStatusEnum.CANCELED.bgColor = '#f7bfbf'
      staffUserSalaryPayStatusEnum.PAYING.color = '#2196F3FF'
      staffUserSalaryPayStatusEnum.PAYING.bgColor = '#2196F31A'
      staffUserSalaryPayStatusEnum.PAY_DONE.color = '#26BF26FF'
      staffUserSalaryPayStatusEnum.PAY_DONE.bgColor = '#26BF261A'
      staffUserSalaryPayStatusEnum.PAY_FAILURE.color = '#ff0000'
      staffUserSalaryPayStatusEnum.PAY_FAILURE.bgColor = '#f7bfbf'
      staffUserSalaryPayStatusEnum.REJECTED.color = '#ff0000'
      staffUserSalaryPayStatusEnum.REJECTED.bgColor = '#f7bfbf'
      staffUserSalaryPayStatusEnum.TO_SUBMIT.color = '#FF7D00FF'
      staffUserSalaryPayStatusEnum.TO_SUBMIT.bgColor = '#FF7D001A'


      const isValidStatus = this.getReferenceConstantMap('isValidEnum')
      isValidStatus.DISABLED.color = 'red'
      isValidStatus.ENABLE.color = 'green'

      const EnableStatuses = this.getReferenceConstantMap('staffStatusEnum')
      EnableStatuses.ON_OFFICE.color = '#00B42A'
      EnableStatuses.ON_OFFICE.bgColor = '#caf0d6'
      EnableStatuses.LEAVE_OFFICE.color = '#A8A8A8'
      EnableStatuses.LEAVE_OFFICE.bgColor = '#ededed'
      EnableStatuses.TO_BE_EMPLOYED.color = '#FF7D00'
      EnableStatuses.TO_BE_EMPLOYED.bgColor = '#FFE5CC'
      EnableStatuses.LEAVE_OFFICE_WAIT.color = '#000000'
      EnableStatuses.LEAVE_OFFICE_WAIT.bgColor = '#D9D9D9'

      const projectStatus = this.getReferenceConstantMap('projectStatusEnum')
      projectStatus.NOT_STARTED.color = 'red'
      projectStatus.IN_PROGRESS.color = 'orange'
      projectStatus.FINISHED.color = 'green'

      const socialAddStatus = this.getReferenceConstantMap('socialAddStatusEnum')
      socialAddStatus.ADD_FAIL.color = 'red'
      socialAddStatus.WAIT_ADD.color = 'orange'
      socialAddStatus.ADD_SUCCESS.color = 'green'


      const socialReduceStatus = this.getReferenceConstantMap('socialReduceStatusEnum')
      socialReduceStatus.REDUCE_FAIL.color = 'red'
      socialReduceStatus.WAIT_REDUCE.color = 'orange'
      socialReduceStatus.REDUCE_SUCCESS.color = 'green'

      const salaryIsLookStatus = this.getReferenceConstantMap('salaryIsLookStatusLocalEnum')
      salaryIsLookStatus.UNLOOK.color = 'orange'
      salaryIsLookStatus.LOOKED.color = 'green'

      const salaryIsSendStatus = this.getReferenceConstantMap('salaryIsSendStatusLocalEnum')
      salaryIsSendStatus.UNSEND.color = 'orange'
      salaryIsSendStatus.SENDED.color = 'green'

      const sureStatusLocalEnum = this.getReferenceConstantMap('sureStatusLocalEnum')
      sureStatusLocalEnum.UNSURE.color = 'orange'
      sureStatusLocalEnum.SURED.color = 'green'

      const feedbackIsSendStatus = this.getReferenceConstantMap('feedbackTypeEnum')
      feedbackIsSendStatus.PENDING.color = 'orange'
      feedbackIsSendStatus.PROCESSED.color = 'green'

      const payrollSendStatus = this.getReferenceConstantMap('sendStatusEnum')
      payrollSendStatus.NOTSEND.color = 'orange'
      payrollSendStatus.SENDSUCCESS.color = 'green'
      payrollSendStatus.WITHDRAW.color = 'red'
      payrollSendStatus.SENDFAILE.color = 'red'

      const resumeCurrentStageStatus = this.getReferenceConstantMap('resumeCurrentStageEnum')
      resumeCurrentStageStatus.TO_BE_INTERVIEW.color = 'orange'
      resumeCurrentStageStatus.TO_BE_FIRST_INTERVIEW.color = 'orange'
      resumeCurrentStageStatus.TO_BE_RETEST.color = 'orange'
      resumeCurrentStageStatus.TO_BE_FINAL_TRIAL.color = 'orange'
      resumeCurrentStageStatus.ENTRY.color = 'green'
      resumeCurrentStageStatus.OFFER.color = 'green'
      resumeCurrentStageStatus.INTERVIEW_FAIL.color = 'red'
      resumeCurrentStageStatus.FIRST_INTERVIEW_FAIL.color = 'red'
      resumeCurrentStageStatus.RETEST_FAIL.color = 'red'
      resumeCurrentStageStatus.FINAL_TRIAL_FAIL.color = 'red'
      resumeCurrentStageStatus.REFUSE_OFFER.color = 'red'
      resumeCurrentStageStatus.CANCEL_ENTRY.color = 'red'
      resumeCurrentStageStatus.FINAL_TRIAL.color = 'blue'
      resumeCurrentStageStatus.RETEST.color = 'blue'
      resumeCurrentStageStatus.FIRST_INTERVIEW.color = 'blue'


      const approvalCheckLocalEnum = this.getReferenceConstantMap('approvalCheckLocalEnum')
      approvalCheckLocalEnum.PASS.color = 'green'
      approvalCheckLocalEnum.PASS.icon = 'icon-icon_right_default'
      approvalCheckLocalEnum.WAIT.color = '#21209C'
      approvalCheckLocalEnum.WAIT.icon = 'icon-icon_wait_default'
      approvalCheckLocalEnum.REJECT.color = 'red'
      approvalCheckLocalEnum.REJECT.icon = 'icon-icon_error_default'


      const oaProcessCheckStatus = this.getReferenceConstantMap('processCheckStatusEnum')
      oaProcessCheckStatus.TO_BE_CHECK.color = '#FF7D00FF'
      oaProcessCheckStatus.TO_BE_CHECK.bgColor = '#FF7D001A'
      oaProcessCheckStatus.CHECK_APPROVED.color = '#26BF26FF'
      oaProcessCheckStatus.CHECK_APPROVED.bgColor = '#26BF261A'
      oaProcessCheckStatus.FAIL_CHECK_APPROVED.color = '#F53F3FFF'
      oaProcessCheckStatus.FAIL_CHECK_APPROVED.bgColor = '#F53F3F1F'
      oaProcessCheckStatus.REJECT.color = '#F53F3FFF'
      oaProcessCheckStatus.REJECT.bgColor = '#F53F3F1F'

      const invoiceAuditStatus = this.getReferenceConstantMap('invoiceAuditStatusEnum')
      invoiceAuditStatus.TO_AUDIT.color = '#FF7D00FF'
      invoiceAuditStatus.TO_AUDIT.bgColor = '#FF7D001A'
      invoiceAuditStatus.AUDITING.color = '#2196F3FF'
      invoiceAuditStatus.AUDITING.bgColor = '#2196F31A'
      invoiceAuditStatus.AUDIT_SUCCESS.color = '#26BF26FF'
      invoiceAuditStatus.AUDIT_SUCCESS.bgColor = '#26BF261A'
      invoiceAuditStatus.AUDIT_FAIL.bgColor = '#F53F3F1F'
      invoiceAuditStatus.AUDIT_FAIL.bgColor = '#F53F3F1F'

      const jobFollowStatus = this.getReferenceConstantMap('jobFollowStatusEnum')
      jobFollowStatus.TO_BE_CHECK.color = 'orange'
      jobFollowStatus.CHECK_APPROVED.color = 'green'
      jobFollowStatus.FAIL_CHECK_APPROVED.color = 'red'
      jobFollowStatus.REJECT.color = 'red'
      jobFollowStatus.FOLLOW.color = 'orange'
      jobFollowStatus.FOLLOWING.color = 'blue'
      jobFollowStatus.FOLLOWED.color = 'green'
      jobFollowStatus.CANCEL.color = 'red'

      const clockStatus = this.getReferenceConstantMap('clockStatusesEnum')
      clockStatus.START_WORK_LATE.color = 'red'
      clockStatus.OFF_WORK_EARLY.color = 'red'
      clockStatus.START_WORK_ABSENT.color = 'orange'
      clockStatus.OFF_WORK_ABSENT.color = 'orange'
      clockStatus.START_WORK_NORMAL.color = 'green'
      clockStatus.OFF_WORK_NORMAL.color = 'green'
      clockStatus.NOT_CLOCK.color = 'red'

      const hiringStatus = this.getReferenceConstantMap('needsStatusEnum')
      hiringStatus.FOLLOW.color = 'blue'
      hiringStatus.FOLLOWED.color = 'green'
      hiringStatus.FOLLOWING.color = 'orange'
      hiringStatus.CANCEL.color = 'red'

      const contractTemplateStatusesEnum = this.getReferenceConstantMap('contractTemplateStatusesEnum')
      contractTemplateStatusesEnum.AUDITED.color = 'green'
      contractTemplateStatusesEnum.AUDITED_FAIL.color = 'red'
      contractTemplateStatusesEnum.AUDIT_WAIT.color = 'yellow'
      const contractStatusEnum = this.getReferenceConstantMap('contractStatusEnum')
      contractStatusEnum.CANCEL.color = 'red'
      contractStatusEnum.DRAFT.color = 'gray'
      contractStatusEnum.SIGNED.color = 'green'
      contractStatusEnum.SIGNING.color = 'yellow'
      contractStatusEnum.TODO.color = 'blue'

      const jobFollowStatusEnum = this.getReferenceConstantMap('jobFollowStatusEnum')
      jobFollowStatusEnum.TO_BE_CHECK.color = 'orange'
      jobFollowStatusEnum.CHECK_APPROVED.color = 'green'
      jobFollowStatusEnum.FAIL_CHECK_APPROVED.color = 'red'
      jobFollowStatusEnum.REJECT.color = 'red'
      jobFollowStatusEnum.FOLLOW.color = 'blue'
      jobFollowStatusEnum.FOLLOWING.color = 'orange'
      jobFollowStatusEnum.FOLLOWED.color = 'green'
      jobFollowStatusEnum.CANCEL.color = 'red'
    },

    // 获取省市区数据
    getAreaData() {
      globalApi.getAreaData().then(res => {
        this.areaData = res
        setItem('areaData', res)
      })
        .catch(err => {
          // 防止某些用户没有权限获取，这里不要调用
          // let timer = setTimeout(() => {
          //   if (this.areaData.length===0) {
          //     this.getAreaData()
          //   }
          //   clearTimeout(timer)
          // }, 20000)
        })
    },

    // 未处理消息
    refreshTimerData(loop?: boolean) {
      projectApi.getIdcardToBeExpiredNum().then(res => {
        this.notifications = res
      })
      messageApi.getUnreadMessageList().then(res => {
        this.messages = res
      })
      // if (loop && import.meta.env.PROD) {
      timer = useTimeoutFn(() => this.refreshTimerData(true), 10000)
      // }
    },

    // 点击消息跳转到对应详情
    async clickMessageToDetail(record) {
      let routerName = ''
      let messageParams = {}
      switch (record.messageEventType) {
        // 合同待签署
        case this.getReferenceConstantMap('messageEventEnum').CONTRACT_WAIT_SIGN.value:
          routerName = 'contractList'
          break
        // 员工待入职
        case this.getReferenceConstantMap('messageEventEnum').STAFF_WAIT_ENTRY.value:
          routerName = 'workerToBeEmployed'
          break
        // 员工待转正
        case this.getReferenceConstantMap('messageEventEnum').STAFF_WAIT_WORKER.value:
          routerName = 'workerToBeConfirmed'
          break
        // 合同即将到期
        case this.getReferenceConstantMap('messageEventEnum').CONTRACT_EXPIRE.value:
          routerName = 'contractToBeExpired'
          break
        // 证件即将到期
        case this.getReferenceConstantMap('messageEventEnum').IDCARD_EXPIRE.value:
          routerName = 'idcardToBeExpired'
          break
        // 工资单反馈
        case this.getReferenceConstantMap('messageEventEnum').WAGE_FEEDBACK.value:
          routerName = 'payrollFeedbackList'
          messageParams = { status: 0 }
          break
        // 单据待审批
        case this.getReferenceConstantMap('messageEventEnum').APPROVE_WAIT_CONFIRM.value:
          routerName = 'oaList'
          break
        // 后端考勤异常
        case this.getReferenceConstantMap('messageEventEnum').PC_CLOCK_ABNORMAL.value:
          routerName = ''
          break
        // 员工工资单待确认
        case this.getReferenceConstantMap('messageEventEnum').STAFF_WAGE_WAIT_CONFIRM.value:
          routerName = ''
          break
        // 员工考勤异常提醒
        case this.getReferenceConstantMap('messageEventEnum').STAFF_CLOCK_ABNORMAL.value:
          routerName = ''
          break
        // 员工单据审批通过
        case this.getReferenceConstantMap('messageEventEnum').STAFF_APPROVE_PASS.value:
          routerName = ''
          break
        // 员工单据审批驳回
        case this.getReferenceConstantMap('messageEventEnum').STAFF_APPROVE_REJECT.value:
          routerName = ''
          break
        // 员工单据审批拒绝
        case this.getReferenceConstantMap('messageEventEnum').STAFF_APPROVE_REFUSE.value:
          routerName = ''
          break
        case this.getReferenceConstantMap('messageEventEnum').SEND_WAGE.value:
          routerName = ''
          break
        // 员工退休提醒
        case this.getReferenceConstantMap('messageEventEnum').STAFF_RETIRE_NOTICE.value:
          routerName = 'workerList'
          // 过滤退休的员工
          messageParams = { messageId: record.id }
          break
        default:
          routerName = ''
      }
      if (routerName) {
        // 设置消息已读
        await messageApi.singleMessageRead(record.id)
        router.push({
          name: routerName,
          params: messageParams
        })
      }
    },

    // 关闭定时器
    async stopTimer() {
      await timer?.stop()
      timer = null
    },
  },
})
